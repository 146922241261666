<template>
  <div class="mainwrap banneredit" v-loading="loading">
    <div class="title">{{title}}</div>
    <div class="formwrap">
      <el-form
          :model="detailFrom"
          ref="detailFrom"
          label-width="150px"
          label-position="left"
          :rules="rules"
      >

        <el-form-item label="用戶帳號" prop="name">
          <el-input
              v-model="detailFrom.name"
          ></el-input>
              <!-- disabled -->
        </el-form-item>
         <el-form-item label="手機號" prop="mobile">
          <el-input
              v-model="detailFrom.mobile"
          ></el-input>
        </el-form-item>
<!--        <el-form-item label="密碼" prop="password">-->
<!--          <el-input type="password" autocomplete="new-password"-->
<!--              v-model="detailFrom.password" -->
<!--              @focus="passwordFocus"-->
<!--              @blur="passwordBlur"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="確認密碼" prop="password2">-->
<!--          <el-input type="password" autocomplete="new-password"-->
<!--              v-model="detailFrom.password2" -->
<!--              @focus="password2Focus"-->
<!--              @blur="password2Blur"-->
<!--          ></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="角色" prop="role">
          <el-select v-model="detailFrom.role" placeholder="请选择角色">
            <el-option
                v-for="item in identityarr"
                :key="item.key"
                :label="item.value"
                :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="還原密碼" v-if="$route.query.type === 'edit'">
          <el-switch v-model="detailFrom.isHasRestorePasswor">
          </el-switch>
        </el-form-item>
      </el-form>
    </div>

    <div class="btnwrap">
      <div class="btnlist">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import {contactUsEdit} from "./api";
export default {
  data() {
    // var validatePass = (rule, value, callback) => {
    //     if (value === '') {
    //         callback(new Error('請輸入密碼'));
    //     }else if (value.length<6 || value.length>20 ) {
    //         callback(new Error('密碼長度應為6-20位數'));
    //     }
    //     else {
    //       callback();
    //     }
    //   };
    //   var validatePass2 = (rule, value, callback) => {
    //     if (value === '') {
    //       callback(new Error('請再次輸入密碼'));
    //     }
    //     else if (value !== this.detailFrom.password) {
    //       callback(new Error('兩次輸入的密碼不一致!'));
    //     }
    //     else {
    //       callback();
    //     }
    //   };
      var mobiles = (rule, value, callback) => {
        let reg_tel = /^(09\d{8})$/ ;
        // let reg_tel = /^[1][3,4,5,6,7,8,9][0-9]{9}$/ ;
        if (value === '') {
          callback(new Error('請輸入手機號'));
        } else if (!reg_tel.test(value)) {
          callback(new Error('請輸入正確的手機號碼'));
        } else {
          callback();
        }
      };
    return {
      loading: false,
      detailId:'',
      identityarr: [
          {
            value:" ",
            key:1
          }
      ],
      identityarrs:"",
      detailFrom:{
        isHasRestorePasswor: false,
        id:'',
        name: "",
        password:"",
        mobile:"",
        password2:"",
        role:'',
        description:"1",
        oldPassword:''
      },
      goon: true,
      rules:{
        role:[{ required: true, message: "請选择角色", trigger: "blur" }],
        name:[{ required: true, message: "請填寫名稱", trigger: "blur" }],
        mobile: [{ validator: mobiles, trigger: 'blur',required: true,}],
      }

      // password: [{ required: true, message: "請輸入密碼", trigger: "blur" },
      //   { validator: validatePass, trigger: 'blur',required: true} ],
      // password2: [{ validator: validatePass2, trigger: 'blur',required: true}],
    };
  },
  created() {
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
      this.title = '編輯信息'
      this.detailId=queryObj.id
      this.getAllroles()
    } else if(queryObj.type && queryObj.type === "create") {
      this.title = '新增用戶'
      this.detailFrom.isHasRestorePasswor = true
      this.getAllroles()
    }
  },

  methods: {
    getDetail(){
      this.loading = true;
      api.userDetail(this.detailId).then(res=>{
        if(res.systemCode===200){
          // res.data.mobile = Number(res.data.mobile);
          this.detailFrom = Object.assign({},this.detailFrom,res.data);
          this.detailFrom.role = res.data.roleId;
          this.detailFrom.oldPassword = res.data.password;
          this.detailFrom.password = res.data.password.substr(0,6);
          this.detailFrom.password2 = res.data.password.substr(0,6);
          this.detailFrom.isHasRestorePasswor = res.data.isHasRestorePasswor;
          this.$nextTick(()=>{
            this.$refs.detailFrom.validateField("mobile");
          })
        }
        this.loading = false;
      })
    },

    passwordFocus(){
      if(this.detailFrom.password==this.detailFrom.oldPassword.substr(0,6)){
        this.detailFrom.password="";
      }
    },
    passwordBlur(){
      if(this.detailFrom.id && this.detailFrom.password===""){
        this.detailFrom.password=this.detailFrom.oldPassword.substr(0,6);
      }
    },
    password2Focus(){
      if(this.detailFrom.password2==this.detailFrom.oldPassword.substr(0,6)){
        this.detailFrom.password2="";
      }
    },
    password2Blur(){
      if(this.detailFrom.id && this.detailFrom.password2===""){
        this.detailFrom.password2=this.detailFrom.oldPassword.substr(0,6);
      }
    },
    // 获取所有角色
    getAllroles() {
       this.loading = true;
       api.getAllrole().then(res=>{
        if(res.systemCode===200){
          this.identityarr = res.data
          if(this.detailId) {
            this.getDetail()
          }
        }
        this.loading = false;
      })
    },
    // 提交
    onSubmit() {
      this.$refs["detailFrom"].validate(valid => {
        if (valid) {
            let params={
              name: this.detailFrom.name,
              mobile: String(this.detailFrom.mobile),
              isHasRestorePasswor: this.detailFrom.isHasRestorePasswor,
              description: this.detailFrom.description,
              roleId:this.detailFrom.role
            }
            if(this.detailFrom.id){
              params.id=this.detailFrom.id;
              if(this.detailFrom.password != this.detailFrom.oldPassword.substr(0,6)) {
                params.password = this.detailFrom.password
              }
              if(this.detailFrom.password == this.detailFrom.oldPassword.substr(0,6)) {
                params.password = this.detailFrom.oldPassword
              }

            }
            if(!this.detailFrom.id) {
              params.password = this.detailFrom.password
            }
            this.loading=true;
            api.userEdit(params).then(res=>{
              if(res.systemCode===200){
                this.$message.success('提交成功');
                this.$router.push({path:"/cms/usermanagement"});
              }else{
                // this.$message.error(res.message);
              }
              this.loading=false;
            })

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 取消
    cancel() {
      this.$router.push({path:"/cms/usermanagement"});
    },
  },
};
</script>

<style lang="scss" scoped>
.banneredit {
  .formwrap {
    padding-top: 20px;
    padding-left: 20px;
    width: 500px;
    .inlineblock {
      .el-input {
        width: 80%;
        margin-right: 10px;
      }
    }
  }
  .linkSearchBox{
    width: 500px;
    margin-bottom: 10px;
    .el-input {
      width: 250px;
      margin-right: 10px;
    }
  }
}
</style>
